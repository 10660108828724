.navbar {
  padding: 0;
  height: 100%;
  font-size: 1rem;
}

.navbar-brand {
  padding: 1.525rem 2.875rem 1.425rem 0;
  margin-right: 0;
}

.navbar-brand svg {
  width: 220px;
  height: auto;
}

.bg-light {
  background-color: #fff !important;
}

.navbar-light .navbar-nav .nav-link {
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
  cursor: pointer;
  padding: 0;
  color: #0a0028;
}

.navbar-light .navbar-nav .nav-link svg {
  margin-left: 0.5rem;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #ff0046;
}


.header__section {
  display: flex !important;
  padding: 0 1.875rem;
  height: 88px;
  border-left: solid 1px rgba(0, 0, 0, .1);
  z-index: 1;
  align-items: center;
}

.header__section--logout {
  min-width: 8rem;
}

.header__volume {
  width: 16rem;
}

.header__separator {
  overflow: hidden;
  position: absolute;
  height: 96px;
  left: 215px;
  z-index: 1;
  width: 310px;
}

.header__separator-content {
  background-image: -moz-linear-gradient( 180deg, rgba(18,13,33,0) 0%, rgba(18,13,33,0.99) 99%, rgb(18,13,33) 100%);
  background-image: -webkit-linear-gradient( 180deg, rgba(18,13,33,0) 0%, rgba(18,13,33,0.99) 99%, rgb(18,13,33) 100%);
  background-image: -ms-linear-gradient( 180deg, rgba(18,13,33,0) 0%, rgba(18,13,33,0.99) 99%, rgb(18,13,33) 100%);
  transform:matrix(0.94,0.34,-0.34,0.94,0,0);
  opacity: 0.102;
  position: absolute;
  top: -100px;
  left: 10px;
  width: 310px;
  height: 400px;
}

.dropdown {
  background-color: white;
  border: 1px solid rgba(10, 0, 40, 0.2);
  border-radius: 2rem;
  color: rgb(10,0,40);
  font-family: "Exo 2", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.9rem;
  font-style: italic;
  display: flex;
  align-items: center;
}

.dropdown button {
  border: none;
}

.dropdown button:hover, .dropdown button:active, .dropdown button:focus {
  background-color: white !important;
  color: rgb(10,0,40) !important;
  box-shadow: none !important;
}

.dropdown__item {
  padding: 0;
  margin: 0;
}

.dropdown__caret {
  margin-left: 1rem;
  font-weight: bold;
  font-style: normal;
  color: #FF0045;
}

.dropdown__menu {
  width: -webkit-fill-available !important;
  top: 5px !important;
}

.dropdown__item a {
  padding: 0.5rem 1rem !important;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: normal;
}

.dropdown__item a:hover {
  background-color: #FF0045 !important;
  color: white !important;
}
.dropdown__item a:hover svg path {
  fill: white !important;
}

/* selectbox */
.selectbox {
  width: 10.5rem;
  vertical-align: middle;
  z-index: 1;
}

.selectbox__control {
  border: solid 1px rgba(10, 0, 40, 0.2) !important;
  border-radius: 2rem !important;
  background: transparent !important;
  height: auto !important;
}

.selectbox__control--is-focused {
  box-shadow: none !important;
}

.header__selectbox .rc-select-selection__rendered {
  height: auto;
}

.selectbox__value-container {
  font-size: 0.875rem;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
  padding: 0.1rem 1.1rem !important;
  color: #0a0028 !important;
  height: auto;
}

.selectbox__indicator-separator {
  display: none;
}

.selectbox__dropdown-indicator svg path {
  fill: #ff0044 !important;
}

.selectbox:hover .selectbox__dropdown-indicator svg path {
  fill: #0a0028 !important;
}

.selectbox__menu {
  color: #0a0028 !important;

}

.selectbox__option:hover {
  background: rgba(0, 0, 0, .05);
}

.selectbox__option--is-focused {
  background: rgba(255, 0, 68, .1) !important;
}

.selectbox__option--is-selected {
  background: #ff0044 !important;
}


@media screen and (max-width: 1280px) {
  .navbar {
    font-size: 0.875rem;
  }

  .header__volume {
    width: 13.5rem;
  }

  .header__section {
    padding: 0 1.25rem;
    height: 78px;
  }

  .navbar-brand {
    margin-right: 20px;
    padding: 1.275rem 1.875rem 1.125rem 0;
  }

  .navbar-brand svg {
    width: 180px;
    margin-left: 20px;
  }

  .header__separator {
    left: 200px;
  }

  .selectbox {
    width: 9.5rem;
  }

  .selectbox__control {
    min-height: 20px !important;
  }

  .selectbox__dropdown-indicator {
    padding: 5px !important;
  }

  .selectbox__value-container {
    padding: 0.1rem 0.875rem !important;
    line-height: 0.875rem;
  }
}

